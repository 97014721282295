import Container from '@components/containers/Container';
import React from 'react';
import { IButtonACF } from 'src/types/IButtonACF';
import Button from '@components/views/Button';
import FeaturedContent, {
  FeaturedContentProps,
} from '@components/views/FeaturedContent';
import ContainImage from '@components/views/ContainImage';
import PatternSVG from '../../../assets/images/pattern.svg';
import GlossyBanners, {
  GlossyBannersCollectionProps,
} from '@components/views/GlossyBanners';

export interface WideCTA extends FeaturedContentProps {
  buttons: Array<{
    button: IButtonACF;
  }>;
  image?: string;
  labels?: GlossyBannersCollectionProps;
}

const imagePlaceholder = '/images/diploma-section-placeholder.png';

/**
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=318%3A4148
 */
const WideCTA: React.FunctionComponent<WideCTA> = (props) => {
  const { buttons, heading, chip, description, image, labels } = props;

  const title = heading?.title;

  return (
    <section className="bg-neutral-1">
      <Container>
        <div className="grid lg:grid-cols-2 items-center gap-16">
          <div className="py-56 lg:py-128">
            {title && (
              <FeaturedContent
                heading={heading}
                description={description}
                chip={chip}
              />
            )}
            {buttons?.length > 0 && (
              <div className="flex flex-wrap items-center mt-32 lg:mt-128 xl:mt-192">
                {buttons.map(({ button: { title, url, variant } }, index) => (
                  <div key={index} className="mr-32 mt-16">
                    <Button href={url} variant={variant || 'PRIMARY'}>
                      {title}
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="relative self-end outer-right xl:pt-80 lg:pr-77">
            <div className="absolute left-1/2 -translate-x-1/2 hidden lg:block">
              <PatternSVG />
            </div>
            <ContainImage src={image ? image : imagePlaceholder} />
            <div className="absolute bottom-16 md:bottom-0 md:top-1/2 w-2/3 left-1/2 -translate-x-1/2 xl:w-full lg:pr-32 xxl:pr-68">
              {labels && labels.length > 0 && (
                <GlossyBanners banners={labels} />
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WideCTA;
