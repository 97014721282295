import { mediaQueries } from '@styles/theme';
import React from 'react';
import styled from 'styled-components';
import GlossyBanner, { GlossyBannerProps } from '../GlossyBanner';

const GlossyBannersWrapper = styled.div`
  display: grid;
  justify-content: flex-end;

  ${mediaQueries.greaterThan('lg')`
    max-height: 30rem;
    overflow: auto;
  `}

  > div:nth-child(odd) {
    ${mediaQueries.greaterThan('xl')`
        grid-column: 2/3;
        justify-self: start;
    `}

    > div {
      border: 1px solid transparent;

      border-image: linear-gradient(
          28.96deg,
          rgb(255, 255, 255, 0.5),
          rgb(255, 255, 255, 0)
        )
        1;
    }
  }

  > div:nth-child(even) {
    ${mediaQueries.greaterThan('xl')`
        grid-column: 1/2;
        justify-self: end;
    `}

    > div {
      border: 1px solid transparent;

      border-image: linear-gradient(
          210.96deg,
          rgb(255, 255, 255, 0.5),
          rgb(255, 255, 255, 0)
        )
        1;
    }
  }
`;

export type GlossyBannersCollectionProps = Array<GlossyBannerProps>;

const GlossyBannerRow = styled.div<{ index: number }>`
  grid-row: ${({ index }) => index && index + 1};
`;

const GlossyBanners = ({
  banners,
}: {
  banners: GlossyBannersCollectionProps;
}) => {
  return (
    <GlossyBannersWrapper className="w-full">
      {banners &&
        banners.map((el, key) => (
          <GlossyBannerRow key={key} index={key}>
            <GlossyBanner {...el} />
          </GlossyBannerRow>
        ))}
    </GlossyBannersWrapper>
  );
};

export default GlossyBanners;
