import React from 'react';
import Chip, { ChipProps } from '@components/views/Chip';
import Heading, { HeadingProps } from '@components/views/Heading';
import omit from 'lodash/omit';

export interface FeaturedContentProps {
  heading?: HeadingProps;
  description?: string;
  chip?: ChipProps;
}

const FeaturedContent: React.FunctionComponent<FeaturedContentProps> = (
  props,
) => {
  const { heading, description, chip } = props;

  return (
    <div className="py-24 lg:py-0">
      <div className="grid items-center gap-8 justify-start">
        {chip && (
          <div className="justify-start">
            <Chip {...omit(chip, ['title'])}>{chip.title}</Chip>
          </div>
        )}
        {heading && (
          <div className="text-lg md:text-2xl xl:text-5xxl font-extrabold">
            <Heading {...heading} />
          </div>
        )}
        {description && (
          <p
            className="text-neutral-5 text-base"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></p>
        )}
      </div>
    </div>
  );
};

export default FeaturedContent;
